<script setup>
import { computed, onBeforeUnmount, onMounted } from 'vue'
import store from '@/store'

function calHeight() {
  const innerheight = window.innerHeight / 100
  document
    .querySelector(':root')
    .style.setProperty('--vh', `${innerheight.toString()}px`)
}

calHeight()

onMounted(() => {
  window.addEventListener('resize', calHeight)
})

onBeforeUnmount(() => {
  window.addEventListener('resize', calHeight)
})

const esMovil = computed(() => Boolean(window.innerWidth < 768))

const cdn_archivos_dominio = process.env.VUE_APP_CDN_ARCHIVOS
const dominio = process.env.VUE_APP_ENI_DOMAIN + process.env.VUE_APP_BASE_ROUTE
const gema_despliegue = process.env.VUE_APP_GEMA
const ambiente_eni = process.env.VUE_APP_ENI
const cdn_archivos_ldn = cdn_archivos_dominio.replace(
  '/soberania-alimentaria/index',
  '/landing'
)
</script>

<template>
  <main id="principal">
    <div class="contenedor-portada">
      <video
        class="portada"
        autoplay
        muted
        loop
        playsinline
      >
        <source
          :src="`${cdn_archivos_dominio}/portada/alimentacion-${
            esMovil ? 'celular' : 'escritorio'
          }.webm`"
          type="video/webm"
        />
      </video>

      <div class="degradado-negro-abajo">
        <div class="contenido-abajo">
          <div class="contenedor ancho-lectura">
            <h1 class="titulo-eni texto-centrado texto-color-1">
              {{ store.state.nombre_eni }}
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div class="contenedor ancho-lectura m-y-10">
      <h2 class="texto-centrado m-y-8-esc m-y-4-mov m-x-auto">
        Ecosistema Nacional Informático de {{ store.state.nombre_eni }}
      </h2>

      <p class="texto-alto m-y-8-esc m-y-4-mov">
        Como parte del derecho de las y los mexicanos para conservar y promover
        la producción de alimentos básicos tomando en cuenta la diversidad
        cultural y productiva de cada comunidad, el Conahcyt, a través del
        Ecosistema Nacional Informático (ENI) de Soberanía Alimentaria, recopila
        y cura datos, resultados de investigaciones, plataformas,
        visualizaciones y herramientas interactivas con formatos interoperables
        y abiertos e impulsa los esfuerzos de instituciones académicas mexicanas
        que han identificado problemas en diversas localidades con la finalidad
        de hacer de ellas el centro de estabilidad agroalimentaria para afrontar
        la sustentabilidad alimentaria y el fortalecimiento del campo en México.
        A continuación, se encuentran los capítulos que conforman este ENI:
      </p>
    </div>

    <div class="contenedor ancho-fijo m-y-10">
      <div
        class="flex flex-contenido-separado flex-columnas-100-al-simplificar"
      >
        <div class="columna-8-esc m-t-3">
          <a
            class="tarjeta-hipervinculo-externo"
            href="https://alimentacion.conahcyt.mx/produccionsinglifosato/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <picture class="fondo-imagen">
              <source
                :src="`${cdn_archivos_dominio}/tarjetas/productos-sin-glifosato.png`"
              />
              <img
                :src="`${cdn_archivos_dominio}/tarjetas/productos-singlifosato-mov.png`"
              />
            </picture>

            <div class="degradado-negro-abajo">
              <div class="contenido-abajo">
                <p class="titulo-pagina texto-color-1">
                  Producción sin Glifosato
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="columna-8-esc m-t-3">
          <a
            class="tarjeta-hipervinculo-externo"
            :href="`${dominio}glifosato`"
            target="_blank"
            rel="noopener noreferrer"
          >
            <picture class="fondo-imagen">
              <img :src="`${cdn_archivos_dominio}/tarjetas/glifosato.jpg`" />
            </picture>

            <div class="degradado-negro-abajo">
              <div class="contenido-abajo">
                <p class="titulo-pagina texto-color-1">
                  Búsqueda de alternativas al Glifosato
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="columna-8-esc m-t-3">
          <a
            class="tarjeta-hipervinculo-externo"
            :href="`${dominio}piesagiles`"
            target="_blank"
            rel="noopener noreferrer"
          >
            <picture class="fondo-imagen">
              <source
                :src="`${cdn_archivos_dominio}/tarjetas/piesagiles-escritorio.jpg`"
              />
              <img
                :src="`${cdn_archivos_dominio}/tarjetas/piesagiles-celular.jpg`"
              />
            </picture>

            <div class="degradado-negro-abajo">
              <div class="contenido-abajo">
                <p class="titulo-pagina texto-color-1">PIES-AGILES</p>
              </div>
            </div>
          </a>
        </div>
        <div class="columna-8-esc m-t-3">
          <a
            class="tarjeta-hipervinculo-externo"
            :href="`${gema_despliegue}/visualizador?seccion=soberania-alimentaria`"
            target="_blank"
            rel="noopener noreferrer"
          >
            <picture class="fondo-imagen">
              <img
                :src="`${cdn_archivos_dominio}/tarjetas/eni-soberania-alimentaria-gema.png`"
              />
            </picture>

            <div class="degradado-negro-abajo">
              <div class="contenido-abajo">
                <p class="titulo-pagina texto-color-1">
                  Explora capas del tema {{ store.state.nombre_eni }}
                </p>
                <hr />
                <p class="subtitulo-capitulo texto-color-1">
                  Gestor de mapas (Gema)
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="columna-8-esc m-t-3">
          <a
            class="tarjeta-hipervinculo-externo"
            :href="`${ambiente_eni}/radiombligo?tematica=Soberanía alimentaria`"
            target="_blank"
            rel="noopener noreferrer"
          >
            <picture class="fondo-imagen">
              <img
                :src="`${cdn_archivos_ldn}/radio-ombligo/ciencia-para-ninas-y-ninos.png`"
              />
            </picture>

            <div class="degradado-negro-abajo">
              <div class="contenido-abajo">
                <p class="titulo-pagina texto-color-1">
                  Ciencia para niñas y niños
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </main>
</template>

<style lang="scss">
.contenedor-portada {
  #v-celular {
    display: block;
    @media (min-width: map-get($breakpoints, 'esc')) {
      display: none;
    }
  }
  #v-escritorio {
    display: none;
    @media (min-width: map-get($breakpoints, 'esc')) {
      display: block;
    }
  }
}

.tarjeta-hipervinculo-externo {
  min-height: 320px;
  .fondo-imagen {
    img {
      width: 100%;
    }
  }
}
</style>
